exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-items-js": () => import("./../../../src/pages/items.js" /* webpackChunkName: "component---src-pages-items-js" */),
  "component---src-pages-lists-js": () => import("./../../../src/pages/lists.js" /* webpackChunkName: "component---src-pages-lists-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-item-page-js": () => import("./../../../src/templates/item-page.js" /* webpackChunkName: "component---src-templates-item-page-js" */),
  "component---src-templates-list-page-js": () => import("./../../../src/templates/list-page.js" /* webpackChunkName: "component---src-templates-list-page-js" */)
}

